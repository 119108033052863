@import './src/scss/variables';
@import './src/scss/app';

.widget__result-text {
  display: flex;
  flex-direction: column;
  gap: 1rem;

	img {
	  width: $min-width;
	  height: $min-width;
	  display: block;
	  mix-blend-mode: multiply;
	}

	&_download {
    display: flex;
    justify-content: center;
    gap: 1rem;

    a {
      text-decoration: none;
      align-items: center;
      gap: .5rem;
    }
	}
}